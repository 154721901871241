import React from 'react'
import {useTranslation} from 'react-i18next'
import LayoutCheckList from 'assets/svgs/LayoutCheckList.svg'

interface Props {
  className?: string
}

const LI = ({children}: {children: React.ReactNode}) => (
  <li className="flex md:py-0 py-2">
    <span className="w-10 py-1.5">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        className="ml-2 mr-3"
      >
        <circle cx="6" cy="6" r="6" fill="#FB923C" />
      </svg>
    </span>
    <p>{children}</p>
  </li>
)

function Page({className = ''}: Props) {
  const {t} = useTranslation()
  return (
    <section
      className={`w-full flex flex-col items-center ${className}`}
    >
      <h2 className="font-head font-extrabold md:text-5xl text-2xl uppercase text-gray-900 max-w-5xl md:px-0 px-4">
        {t(
          'Start Monitoring your Search Space and Stop Trademark Infringements with our Paid Search Tool'
        )}
      </h2>
      <p
        className="md:text-3xl text-base px-4 text-center text-gray-500 max-w-7xl
       md:my-12 my-6"
      >
        {t(
          'We find infringing ads with our PPC tool and help you take them down in just a few clicks.'
        )}
      </p>
      <div className="w-full max-w-7xl md:my-10 my-4 md:px-0 px-4 relative">
        <div
          className="flex items-center justify-center p-5 rounded-xl bg-red-100
         md:text-3xl text-lg font-medium text-center text-gray-900
         md:leading-relaxed leading-6"
        >
          <p className="max-w-5xl">
            {t(
              'What are the dangers of Unauthorized trademark bidders'
            )}
            <span className="lg:block lg:ml-0 ml-1.5 text-red-400">
              {t('bidding on your branded keyword terms?')}
            </span>
          </p>
        </div>
        <img
          src={LayoutCheckList}
          alt="check list"
          className="absolute xl:-right-10 right-1 xl:top-10 lg:top-30 xl:mt-0 -my-8 xl:h-60 lg:h-40 h-20"
        />

        <div
          className="w-full py-10  rounded-xl bg-stone-50 my-8
          flex items-center justify-center
        "
        >
          <ul
            className="md:text-lg text-sm
          md:leading-10 leading-relaxed
           text-left text-gray-500 max-w-5xl px-5
          "
          >
            <LI>
              Decrease in your CTR{' '}
              <span className="text-gray-400">
                (click-through-rate)
              </span>
              for your in-house search Ads.
            </LI>

            <LI>
              A lower Ad position on your branded keyword terms.
            </LI>

            <LI>
              Increase in your CPC{' '}
              <span className="text-gray-400">
                (cost-per-click)
              </span>{' '}
              on branded keyword terms.
            </LI>

            <LI>
              Loss of traffic, i.e. Impression shares drop for
              your in-house search Ad.
            </LI>

            <LI>
              Misrepresentation of your brand messaging via
              Unauthorized and inaccurate Ad copies.
            </LI>

            <LI>
              Reduction of your company's Affiliate program budget
              when your branded keyword terms are obtained without
              an Authorized PPC campaign.
            </LI>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Page
